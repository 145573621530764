Vue.component('training-types-component', {
    mixins: [Mixins.dataset],
    data: function () {
        return {
        }
    },
    props: [ 'delayFirstGet' ],
    computed: {
    },
    created: function () {
        if(this.$props.delayFirstGet) {
            this.$root.$on('chatroom:first-get', this.firstGet);
        } else {
            this.firstGet();
        }
    },
    mounted: function() {
    },
    methods: {
    }
});
