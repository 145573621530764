(function() {
    Vue.use(VeeValidate, {
        locale: document.documentElement.lang,
        events: 'blur|submit|change',
        classes: true,
        invalidateFalse: true,
        strict: false,
        classNames: {
            invalid: 'input--invalid',
            valid: 'input--valid'
        },
        dictionary: {
            nl: {
                messages: {
                    _default: (field) => `Dit veld is niet correct ingevuld.`,
                    after: (field, [target, inclusion]) => `Dit veld moet groter ${inclusion ? 'of gelijk aan ' : ''} ${target}.`,
                    alpha_dash: (field) => `Dit veld mag alleen alfanumerieke karakters, strepen en onderstrepingstekenen bevatten.`,
                    alpha_num: (field) => `Dit veld mag alleen alfanumerieke karakters bevatten.`,
                    alpha_spaces: (field) => `Dit veld mag alleen alfanumerieke karakters en spaties bevatten.`,
                    alpha: (field) => `Dit veld mag alleen alfabetische karakters bevatten.`,
                    before: (field, [target, inclusion]) => `De waarde van dit veld moet kleiner ${inclusion ? 'of gelijk aan' : ''} ${target} zijn.`,
                    between: (field, [min, max]) => `De waarde van dit veld moet tussen ${min} en ${max} zijn.`,
                    confirmed: (field) => `Dit bevestigingsveld komt niet overeen.`,
                    count: (field, [min, max]) => {
                        if (max) {
                            return `Dit veld moet minimaal ${min} item en maximaal ${max} items bevatten.`;
                        }

                        return `Dit veld moet minimaal ${min} item bevatten.`;
                    },
                    credit_card: (field) => `Dit veld is ongeldig.`,
                    date_between: (field, [min, max]) => `De datum moet tussen ${min} en ${max} zijn.`,
                    date_format: (field, [format]) => `Dit veld moet een geldige datum bevatten.`,
                    decimal: (field, [decimals = '*'] = []) => `Dit veld mag alleen numerieke, en${!decimals || decimals === '*' ? ' ' : decimals}decimale nummers bevatten.`,
                    digits: (field, [length]) => `Dit veld moet ${length} nummers bevatten.`,
                    dimensions: (field, [width, height]) => `De dimensies voor dit veld moet ${width} pixels breed en ${height} pixels hoog zijn.`,
                    email: (field) => `Dit veld moet een geldig e-mailadres bevatten.`,
                    ext: (field) => `Dit veld moet een correct bestand bevatten.`,
                    image: (field) => `Dit veld moet een afbeelding bevatten.`,
                    included: (field) => `Dit veld moet een geldige waarde bevatten.`,
                    integer: (field) => `Dit veld moet een nummer zijn.`,
                    ip: (field) => `Dit veld moet een veilig ip adres zijn.`,
                    length: (field, [length, max]) => {
                        if (max) {
                            return `Dit veld moet minimaal ${length} karakters en maximaal ${max} karakters bevatten.`;
                        }

                        return `Dit veld moet minimaal ${length} karakters lang zijn.`;
                    },
                    max: (field, [length]) => `Dit veld mag niet meer karakters bevatten dan ${length}.`,
                    max_value: (field, [max]) => `Dit veld mag een maximale waarde hebben van ${max}.`,
                    mimes: (field) => `Dit veld moet Dit juiste type bestand bevatten.`,
                    min: (field, [length]) => `Dit veld moet minimaal ${length} karakters zijn.`,
                    min_value: (field, [min]) => `Dit veld moet een minimale waarde hebben van ${min}.`,
                    excluded: (field) => `Dit veld moet een geldige waarde bevatten`,
                    numeric: (field) => `Dit veld mag alleen numerieke karakters bevatten.`,
                    regex: (field) => `Dit veld is niet correct ingevoerd.`,
                    required: (field) => `Dit veld is verplicht.`,
                    size: (field, [size]) => `De bestandsgrootte van dit veld mag niet groter zijn dan ${formatFileSize(size)}.`,
                    url: (field) => `Dit veld moet een valide URL zijn.`,
                }
            }
        }
    });
    Vue.use(VuejsDialog.main.default);
    Vue.use(VueObserveVisibility);
})();

let app;
Barba.Dispatcher.on("transitionCompleted", function () {
    app = new Vue({
        el: document.getElementById("app"),
        store,
        mixins: [
            Mixins.smoothScroll,
            Mixins.submitForm
        ],
        components: {
            'vue-recaptcha': VueRecaptcha,
        },
        data() {
            return {
                bLazy: null,
                MoveToInstance: new MoveTo({
                    tolerance: 100,
                    duration: 800
                }),
                hasCaptcha: false,
                captchaExecuted: false,
                menuExpanded: false
            }
        },
        directives: {
            clickOutside: {
                bind: function (el, binding, vNode) {
                    // Provided expression must evaluate to a function.
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name;
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
                        if (compName) {
                            warn += `Found in component '${compName}'`
                        }

                        console.warn(warn)
                    }
                    // Define Handler and cache it on the element
                    const bubble = binding.modifiers.bubble;
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    };
                    el.__vueClickOutside__ = handler;

                    // add Event Listeners
                    document.addEventListener('click', handler)
                },
                unbind: function (el, binding) {
                    // Remove Event Listeners
                    document.removeEventListener('click', el.__vueClickOutside__);
                    el.__vueClickOutside__ = null
                }
            }
        },
        methods: {
            Init() {
                this.Blazy();
                this.Barba();
                this.Veevalidate();
                this.SmoothScroll();
                this.ViewportHeight();
            },
            ViewportHeight(){
                // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
                let vh = window.innerHeight * 0.01;
                // Then we set the value in the --vh custom property to the root of the document
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            },
            Blazy() {
                this.bLazy = new Blazy({
                    breakpoints: [{
                        width: 0,
                        src: 'data-src-small'
                    }, {
                        width: 640,
                        src: 'data-src-medium'
                    }, {
                        width: 1024,
                        src: 'data-src-large'
                    }, {
                        width: 1200,
                        src: 'data-src-xlarge'
                    }, {
                        width: 1440,
                        src: 'data-src-xxlarge'
                    }]
                })
            },
            Barba() {
                let FadeTransition = Barba.BaseTransition.extend({
                    start: function () {
                        // As soon the loading is finished and the old page is faded out, let's fade the new page
                        Promise
                            .all([this.newContainerLoading, this.fadeOut()])
                            .then(this.fadeIn.bind(this));
                    },

                    fadeOut: function () {
                        return new Promise((resolve, reject) => {
                            document.querySelector('.barba-bg').classList.add('barba-bg--visible');
                            document.querySelector('.body').classList.remove('body--loaded');
                            window.setTimeout(function () {
                                resolve();
                            }, 150);
                        });
                    },

                    fadeIn: function () {
                        (this.oldContainer).style.display = 'none';

                        window.scrollTo(0, 0);

                        document.querySelector('.barba-bg').classList.remove('barba-bg--visible');

                        setTimeout(function(){
                            document.querySelector('.body').classList.add('body--loaded');
                            window.dispatchEvent(new Event('resize'));
                        }, 250);

                        this.done();
                    }
                });

                Barba.Pjax.getTransition = function () {
                    return FadeTransition;
                };

                Barba.Dispatcher.on('newPageReady', (currentStatus, oldStatus, container, newPageRawHTML) => {
                    let regexp = /\<body.*\sclass=["'](.+?)["'].*\>/gi,
                        match = regexp.exec(newPageRawHTML);
                    if(!match || !match[1]) return;
                    document.body.setAttribute('class', match[1]);
                });


                const that = this;
                Barba.Dispatcher.on('initStateChange', function () {
                    that.$destroy();
                    Barba.Dispatcher.off('initStateChange');
                });
            },
            Veevalidate() {
                this.$validator.extend('password', {
                    getMessage: (field) => `Dit veld moet minimaal 8 tekens bevatten, waarvan 1 hoofdletter en 1 getal.`,
                    validate: function(value) {

                        let regexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/);

                        return (value.length >= 8 && regexp.test(value));
                    }
                });

                this.$validator.extend('date_past', {
                    getMessage: (field) => `De datum moet in het verleden liggen.`,
                    validate: function(value) {
                        return moment(value).isSameOrBefore(moment(), 'day')
                    }
                });

                this.$validator.extend('date_future', {
                    getMessage: (field) => `De datum moet in de toekomst liggen.`,
                    validate: function(value) {
                        return moment(value).isSameOrAfter(moment(), 'day')
                    }
                });

                this.$validator.extend('datepicker', {
                    getMessage: function(field, params) {
                        let min = moment(params[0]).format('l');
                        let max = moment(params[1]).format('l');

                        return `De datum moet tussen ${min} en ${max} zijn.`;
                    },
                    validate: function(value, params) {

                        let dateGiven = Date.parse(value);
                        let dateFrom = Date.parse(params[0]);
                        let dateTo = Date.parse(params[1]);

                        return (dateFrom <= dateGiven && dateTo >= dateGiven);
                    }
                });

                this.$validator.extend('required_without', {
                    options: {
                        hasTarget: true,
                        computesRequired: true
                    },
                    getMessage: (field) => `Minimaal 1 van deze velden is verplicht.`,
                    validate: function(value, ref) {

                        let refLength = String(ref[0]).trim().length;
                        let valueLength = String(value).trim().length;

                        let isRequired = (!refLength && !valueLength);
                        let isValid = (refLength > 0 || valueLength > 0);

                        return {
                            valid: isValid,
                            data: {
                                required: isRequired
                            }
                        };
                    }
                });

                this.$validator.extend('iban', {
                    getMessage: (field) => `Dit veld moet moet een geldig IBAN nummer bevatten.`,
                    validate: function(value) {
                        const rearranged = value.substring(4,value.length) + value.substring(0,4);
                        const numeric   = Array.from(rearranged).map(c =>(isNaN(parseInt(c)) ? (c.charCodeAt(0)-55).toString() : c)).join('');
                        const remainder = Array.from(numeric).map(c => parseInt(c)).reduce((remainder, value) => (remainder * 10 + value) % 97,0);
                        return  remainder === 1;
                    }
                });
            },
            Axios() {
                // Add a response interceptor
                axios.interceptors.response.use(function (response) {
                    // Do something with response data
                    return response;
                }, function (error) {
                    // Do something with response error
                    // todo error handling
                    return Promise.reject(error);
                });
            },
            submit: function () {
                const that = this;
                this.$validator.validateAll().then(result => {
                    let analytics = that.$el.dataset.analytics;

                    if (result) {
                        if (!this.hasCaptcha || this.captchaExecuted) {
                            that.submitForm(that.$el, analytics);
                        } else {
                            this.$refs.recaptcha.execute();
                        }
                    }
                });
            },
            onCaptchaVerified: function () {
                this.captchaExecuted = true;
                this.submit();
            }
        },
        created() {
            this.Init();
        },
        mounted() {
        }
    })
});

document.addEventListener("DOMContentLoaded", function () {
    document.querySelector('body').classList.add('body--loaded');
    Barba.Pjax.cacheEnabled = false;
    Barba.Pjax.start();
});

let loadedGoogleMapsAPI = new Promise((resolve, reject) => {

    window['GoogleMapsInit'] = resolve;

    let GMap = document.createElement('script');

    GMap.setAttribute('src',
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyA30f0K4Ba8M4I7y14Xbx_VsCnh-7qjdso&callback=GoogleMapsInit&region=NL`);

    document.body.appendChild(GMap);
});
