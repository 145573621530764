Vue.component('meet-up-report-form-component', {
    mixins: [Mixins.reportForm],
    data() {
        return {}
    },
    mounted() {
        this.readyForInput = true;

        if (!this.$props.editing) {
            let meetUpParam = this.getQueryStringParameter(window.location, 'meet_up');
            if (meetUpParam !== null) {
                this.nextStep();
            }
        }
    },
});
