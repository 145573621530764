Vue.component('sidebar-component', {
    data: function () {
        return {
            hasCaptcha: false,
            captchaExecuted: false
        }
    },
    computed: {
        menuExpanded: function (){
            return this.$root.menuExpanded;
        }
    },
    mounted: function () {
        this.hover();
        this.checkIfHover();
    },
    methods: {
        checkIfHover: function () {
            let active = document.querySelectorAll('.sidebar-nav__item--hover').length;
            if(active > 0) {
                let sidebarNav = document.querySelector('.sidebar-nav');
                sidebarNav.classList.add('sidebar-nav--sub-active');
            }
        },

        hover: function () {
            let sidebarActiveItem = document.querySelector('.sidebar-nav__item--active');
            let sidebarActiveOffset = 0;

            if (sidebarActiveItem) {
                sidebarActiveOffset = sidebarActiveItem.offsetTop;
            }
            document.querySelector('.sidebar-nav__indicator').style.top = sidebarActiveOffset+"px";

            let sidebarHover;
            if (sidebarActiveItem) {
                document.querySelectorAll('.sidebar-nav__item').forEach((item) => {
                    item.addEventListener("mouseover", function (event) {
                        sidebarHover = item.offsetTop;
                        document.querySelector('.sidebar-nav__indicator').style.top = sidebarHover + "px";
                    });

                    item.addEventListener("mouseout", function () {
                        document.querySelector('.sidebar-nav__indicator').style.top = sidebarActiveOffset + "px";
                    });
                });
            }
        }
    },
});