Vue.component('profile-form-component', {
    mixins: [Mixins.reportForm],
    props: ['languageskillsUrl', 'programsUrl', 'userImage'],
    data: function () {
        return {
            languageskills: [],
            programs: [],
            profileStrength: 0,
            cropperOpen: false,
            cropperLoading: false,
            newUserImage: null,
            birthdate: ''
        }
    },
    watch: {
        status: function(newValue, oldValue) {
            if(oldValue != 'done' && newValue == 'done') {
                this.status = 'finished';
                this.bootLinkedData();
            }
        }
    },
    computed: {
        profileScoreCover: function () {
            return 100 - this.profileStrength;
        },
        userImageSrc: function() {
            if(this.newUserImage !== null) {
                return this.newUserImage;
            }

            return this.userImage;
        }
    },
    mounted: function () {

        this.linkedData = [
            {
                url: this.$props.languageskillsUrl,
                data: 'languageskills',
                fallback: this.addLanguageskill
            },
            {
                url: this.$props.programsUrl,
                data: 'programs',
                fallback: null
            },
        ];

        if(this.$props.editing) {
            this.bootLinkedData();
        } else {
            this.addLanguageskill();
            this.readyForInput = true;

            this.setProfileStrength();
            this.changeProfileInfo();
        }


    },
    methods: {

        bootLinkedData() {
            this.getLinkedData(0, function() {
                setTimeout(function() {
                    this.setProfileStrength();
                    this.changeProfileInfo();
                }.bind(this), 1);

            });
        },

        cropperUploaded(response) {
            this.newUserImage = response.data.url;
            this.cropperLoading = false;
        },

        addLanguageskill: function () {
            this.languageskills.push({
                id: null,
                language: null,
                level: null,
                type: null,
                date: ''
            });
        },
        deleteLanguageskill: function (x, item) {
            // Item splicen
            this.languageskills.splice(x, 1);
        },

        setProfileStrength: function () {
            let values = this.calculateStrength();
            let score = values.score;
            let fields = values.fields;

            this.profileStrength = Math.floor((100 / fields) * score);
        },

        calculateStrength: function () {
            let inputs = document.querySelectorAll('.account__input');
            let fields = inputs.length;
            let score = 0;

            if(inputs.length > 0) {
                inputs.forEach((input) => {
                    if (input.classList.contains('account__input--multiple')) {
                        let checked = input.querySelectorAll('input:checked')
                        if (checked.length > 0) {
                            score++;
                        }
                    } else if(input.value && input.value.length > 0) {
                        score++;
                    }
                });
            }

            return {
                'score': score,
                'fields': fields
            };
        },

        changeProfileInfo: function () {
            let inputs = document.querySelectorAll('.account__input');
            let that = this;

            if (inputs.length > 0) {
                inputs.forEach((input) => {
                    input.addEventListener('change', function(){

                        let values = that.calculateStrength();
                        let score = values.score;
                        let fields = values.fields;

                        that.profileStrength = parseInt(Math.floor((100 / fields) * score));
                    });
                });
            }
        }
    }
});
