Mixins.dataset = {
    mixins: [Mixins.queryString],
    props: ['url', 'isolate', 'returnTo'],
    data: function () {
        return {
            useUrl: null,
            status: 'new',
            progress: null,
            timerProgress: null,
            data: null,
            buttons: null,
            rawData: null,
            filter: null,
            count: null,
            timerGet: null,
            timerPutOrPost: null,
            interval: 1000,
            continuesGet: false,
            cancelToken: null

        }
    },
    computed: {
        done: function() {
            return (this.status == 'done');
        },
        error: function() {
            return (this.status == 'failed');
        },
        loading: function() {
            return (this.status == 'new' || this.status == 'pending' || this.status == 'busy');
        },
        submitting: function() {
            return (this.status == 'pending' || this.status == 'busy');
        },
        hasData: function() {
            return (this.status == 'done' && Object.keys(this.data).length > 0);
        }
    },
    watch: {
        status(newValue, oldValue) {
            this.$emit('change', {
                status: newValue,
                previousStatus: oldValue
            });
        }
    },
    watch: {
        status(newValue, oldValue) {
            this.$emit('change', {
                status: newValue,
                previousStatus: oldValue
            });
        }
    },
    mounted: function () {
    },
    methods: {
        pageGet: function(page, callback = null) {
            let url = this.applyFilterParametersToUrl(this.$props.url);
            url = this.updateQueryStringParameter(url, 'p', page);

            this.requestGet(url, callback = null);
        },
        filterGet: function(callback = null) {
            let url = this.applyFilterParametersToUrl(this.$props.url);

            this.requestGet(url, callback);
        },
        firstGet: function(callback = null) {
            this.progress = 0;
            let url = this.updateQueryStringParameter(this.$props.url, 'first', 1);
            this.requestGet(url, callback);
        },
        retryGet: function(callback = null) {
            this.status = 'new';
            this.progress = 0;
            let url = this.updateQueryStringParameter(this.$props.url, 'retry', 1);
            this.requestGet(url, callback);
        },
        stopGet: function() {
            clearTimeout(this.timerGet);
            clearInterval(this.timerProgress);
        },
        requestGet: function(url, callback = null) {
            const that = this;

            if(this.$props.isolate != null) {
                url = this.updateQueryStringParameter(url, 'isolate', this.$props.isolate);
            }

            if(this.$props.returnTo != null) {
                url = this.updateQueryStringParameter(url, 'return', this.$props.returnTo);
            }

            axios({
                method: 'get',
                url: url,
                cancelToken: this.cancelToken.token
            }).then(function (response) {

                that.handleResponse(response);

                if(typeof callback == 'function') {
                    callback(response);
                }

                if(that.loading || that.continuesGet) {
                    let nextUrl = that.clearParametersFromUrl(url);
                    that.timerGet = setTimeout(that.requestGet.bind(that, nextUrl, callback), that.interval);
                }

                if(!that.loading && that.timerProgress) {
                    clearInterval(that.timerProgress);
                }

            }).catch(function (error) {
                that.status = 'failed';
                that.progress = 100;

                if(that.timerProgress) {
                    clearInterval(that.timerProgress);
                }

                if(typeof(console) != 'undefined') {
                    console.log(error);
                }
            });

            clearInterval(that.timerProgress);
            this.timerProgress = setInterval(function() {
                if(this.progress < 100) {
                    this.progress++;
                } else {
                    clearInterval(this.timerProgress);
                }
            }.bind(this), 50);
        },

        requestPost: function(url, data) {
            this.requestPutOrPost('post', url, data);
        },

        requestPut: function(url, data) {
            this.requestPutOrPost('put', url, data);
        },

        requestDelete: function(url, dialog) {
            this.requestPutOrPost('delete', url, {}, dialog);
        },

        requestPutOrPost: function(method, url, data, dialog) {
            const that = this;

            axios({
                method: method,
                url: url,
                data: data
            }, {
                cancelToken: this.cancelToken.token
            }).then(function (response) {

                that.handleResponse(response, dialog);

                if(response.data.status != 'done' && response.data.status != 'failed' && response.data.id) {
                    let nextUrl = that.clearParametersFromUrl(that.updateQueryStringParameter(url, '__status', response.data.id));
                    that.timerPutOrPost = setTimeout(that.requestPutOrPost.bind(that, method, nextUrl, data, dialog), that.interval);
                }
            }).catch(function (error) {
                that.status = 'failed';
                if(typeof(console) != 'undefined') {
                    console.log(error);
                }
            });

        },

        handleResponse: function(response, dialog) {

            /*if (response.data.data) {
                console.log('response.data.data', response.data.data);
                console.log('this.continuesGet', this.continuesGet);
                console.log('this.status', this.status);
                console.log('response.data.data.length', response.data.data.length);
            }*/

            if (response.data) {
                this.rawData = response.data;

                if (response.data.filter) {
                    this.filter = response.data.filter;
                }

                if (response.data.buttons) {
                    this.buttons = response.data.buttons;
                }

                if (response.data.buttons) {
                    this.buttons = response.data.buttons;
                }

                if (response.data.count) {
                    this.count = response.data.count;
                }

                if (response.data.data) {
                    this.data = response.data.data;
                }

                this.status = response.data.status;

                if(this.status != 'done') {
                    if(this.progress == null || (response.data.progress !== null && response.data.progress > this.progress)) {
                        this.progress = response.data.progress;
                    }
                } else {
                    this.progress = 100;
                    if(typeof(dialog) != 'undefined') {
                        dialog.close();
                    }
                }

                if (response.data.target) {
                    Barba.Pjax.goTo(response.data.target);
                    return;
                }
            }
        },

        clearParametersFromUrl: function(url) {
            url = this.removeQueryStringParameter(url, 'first');
            url = this.removeQueryStringParameter(url, 'retry');
            return url;
        },

        applyFilterParametersToUrl: function(url) {
            for(var a in this.filter) {
                url = this.updateQueryStringParameter(url, a, this.filter[a].value);
            }

            return url;
        },

    },
    created: function () {
        this.cancelToken = axios.CancelToken.source();
    },
    beforeDestroy: function () {
        this.cancelToken.cancel('Component is beeing destroyed');
        clearTimeout(this.timerGet);
        clearTimeout(this.timerPutOrPost);
        clearInterval(this.timerProgress);
    }
}
