Vue.component('promotions-participate-component', {
    mixins: [Mixins.dataset],
    data: function () {
        return {
            isActive: false,
        }
    },
    created: function () {
        this.firstGet();
    },
    methods: {
        signIn(item) {
            item.participating = 1;
            this.signInOrOut(item);
        },
        signOut(item) {
            item.participating = 0;
            this.signInOrOut(item);
        },
        signInOrOut(item) {
            this.status = 'pending';

            let formData = new FormData();
            formData.append('_method', 'PUT');
            formData.append('promotion', item.external_id);
            formData.append('participating', item.participating);

            let url = this.updateQueryStringParameter(this.$props.url, 'retry', 1);
            this.requestPost(url, formData);
        }
    }
});
