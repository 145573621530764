Vue.component('meet-up-volunteers-component', {
    mixins: [Mixins.dataset],
    data: function () {
        return {}
    },
    methods: {
        firstGetWithLog() {
            this.firstGet();
        }
    },
    created: function () {
        this.$root.$on('meet-up:first-get', this.firstGetWithLog);
    }
});
