Vue.component('presents-report-form-component', {
    mixins: [Mixins.reportForm],
    data: function() {
        return {
            reportInfo: ''
        }
    },
    mounted: function () {
        this.readyForInput = true;

        if(!this.$props.editing) {
            let presentParam = this.getQueryStringParameter(window.location, 'present');
            if(presentParam !== null) {
                this.nextStep();
            }
        }
    },
});
