Vue.component('meet-up-activity-component', {
    props: {
        activity: Object,
        index: Number,
    },
    mounted() {
        let self = this;
    },
    methods: {
        signUp() {
            this.$emit('sign-up', this.index);
        },
        signOut() {
            this.$emit('sign-out', this.index);
        }
    }
});
