Vue.component("header-component", {
    props: ['cookieName'],
    data: function () {
        return {
            scrolled: false,
            userMenuOpen: false
        }
    },
    computed: {
        menuExpanded: function (){
            return this.$root.menuExpanded;
        }
    },
    mounted: function () {
        let that = this;
        window.addEventListener('scroll', function () {
            that.watchScrollPosition();
        });

        this.watchScrollPosition();
    },
    methods: {
        toggleMenu: function () {
            this.$root.menuExpanded = this.$root.menuExpanded!== true;
        },
        watchScrollPosition: function () {
            let that = this;
            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

            if (scrollTop > 50) {
                that.scrolled = true;
            } else {
                that.scrolled = false;
            }
        },
        showSplashscreen() {
            this.$cookies.remove(this.cookieName);
            window.location.reload();
        }
    },
});
