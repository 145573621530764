Vue.component('dossiers-report-form-component', {
    props: {
        guidancecontactsUrl: {
            type: String
        }
    },
    mixins: [Mixins.reportForm],
    data: function () {
        return {
            guidancecontacts: [],
            attachments: [],
            next_contact_at: ''
        }
    },
    mounted: function () {

        this.linkedData = [
            {
                url: this.$props.guidancecontactsUrl,
                data: 'guidancecontacts',
                fallback: this.addGuidancecontact,
                onStep: 2,
                name: 'guidancecontact',
                min: 1
            },
        ];

        if(this.$props.editing) {
            this.getLinkedData();
        } else {
            this.addGuidancecontact();
            this.readyForInput = true;

            let dossierParam = this.getQueryStringParameter(window.location, 'dossier');
            if(dossierParam !== null) {
                this.nextStep();
            }
        }
    },
    methods: {
        addGuidancecontact: function () {
            // Push the new item
            this.guidancecontacts.push({
                id: null,
                type: null,
                date: ''
            });

            // Re-validate the data
            this.validateLinkedData()
        },
        deleteGuidancecontact: function (x, item) {
            // Item splicen
            this.guidancecontacts.splice(x, 1);

            // Re-validate the data
            this.validateLinkedData()
        },
        limit: function(elem){

            var currentLength = elem.target.value.length;
            var maxLength = elem.target.dataset.maxlength;

            if (currentLength >= maxLength) {
                elem.target.value = elem.target.value.substr(0, maxLength);
            }

            var counter = elem.target.parentElement.getElementsByTagName("div").item(0)

            if (counter === null) {
                var counter = document.createElement("div");
            }

            counter.setAttribute("id", "charCounter");
            counter.innerHTML = elem.target.value.length + " / " + maxLength + " tekens";
            elem.target.parentElement.appendChild(counter);
        }
    }
});
