Vue.component('sos-courses-volunteers-component', {
    mixins: [ Mixins.dataset ],
    data: function () {
        return {
        }
    },
    created: function () {
        this.$root.$on('soscourse:first-get', this.firstGet);
    }
});
