Mixins.reportForm = {
    mixins: [Mixins.dataset],
    props: {
        editing: {
            type: Boolean,
            default: false
        },
    },
    data: function () {
        return {
            elements: null,
            linkedData: null,
            linkedDataErrors: {},
            activeReportStep: 1,
            totalSteps: 0,
            readyForInput: false,
            formIsValid: true
        }
    },
    computed: {
        showAllSteps: function() {
            return this.$props.editing;
        },
        enabled: function() {
            return (this.status != 'pending' && this.status != 'busy' && this.readyForInput);
        }
    },
    mounted() {
        this.populateElements();
    },
    methods: {
        getLinkedData: function(iterator = 0, callback) {
            let linkedData = this.linkedData;
            if(typeof(linkedData[iterator]) == 'undefined') {
                this.readyForInput = true;

                if(typeof(callback) == 'function') {
                    callback.call(this);
                }

                return false;
            }

            const that = this;
            const linkedDataItem = linkedData[iterator];
            const linkedIterator = iterator;
            const linkedCallback = callback;

            axios({
                method: 'get',
                url: linkedDataItem.url,
            }).then(function (response) {

                if (response.data.data) {
                    that[linkedDataItem.data] = response.data.data;
                }

                // Support dataset (untested)
                if(typeof(response.data.status) != 'undefined') {
                    if(this.status == 'new' || this.status == 'pending' || this.status == 'busy') {
                        setTimeout(that.getLinkedData.bind(that, linkedIterator, linkedCallback), that.interval);
                    } else {
                        if(linkedDataItem.data == null || linkedDataItem.data.length == 0) {
                            linkedDataItem.fallback();
                        }
                        that.getLinkedData((linkedIterator+1), linkedCallback);
                    }
                } else {
                    if(linkedDataItem.data == null || linkedDataItem.data.length == 0) {
                        linkedDataItem.fallback();
                    }
                    that.getLinkedData((linkedIterator+1), linkedCallback);
                }

            }).catch(function (error) {
                //console.error(error);
            });
        },
        populateElements() {
            // totalSteps berekenen
            let stepColl = this.$el.querySelectorAll('[data-step]');
            let totalSteps = stepColl.length;
            if(totalSteps > 0) {
                this.elements = {};
                for(let s = 0 ; s < totalSteps ; s++) {
                    this.elements[(s+1)] = [];
                    let inputElements = stepColl[s].querySelectorAll('[name]');
                    if(inputElements.length > 0) {
                        for(let i = 0 ; i < inputElements.length ; i++) {
                            this.elements[(s+1)].push(inputElements[i].name);
                        }
                    }
                }
            }
            this.totalSteps = totalSteps;
        },
        validateLinkedData() {

            this.linkedDataErrors = {};

            if(this.linkedData !== null && this.totalSteps > 0) {
                this.linkedData.forEach(item => {
                    let invalid = false;
                    if (item.hasOwnProperty('name') && item.hasOwnProperty('onStep') && this.activeReportStep == item.onStep && (item.hasOwnProperty('min') || item.hasOwnProperty('max'))) {
                        let currentCount = (this[item.data].length || 0);
                        if (item.hasOwnProperty('min') && currentCount < parseInt(item.min)) {
                            invalid = true;
                        } else if (item.hasOwnProperty('max') && currentCount > parseInt(item.max)) {
                            invalid = true;
                        }
                    }
                    item.invalid = invalid;
                    if (item.invalid && item.hasOwnProperty('name')) {

                        let message = 'Error';
                        if (typeof (this.$validator.dictionary) != 'undefined') {
                            message = this.$validator.dictionary.container[this.$validator.dictionary.locale].messages.count(
                                item.name,
                                [
                                    (item.hasOwnProperty('min') ? item.min : null),
                                    (item.hasOwnProperty('max') ? item.max : null)
                                ]
                            );
                        }

                        this.linkedDataErrors[item.name] = message;
                    }
                });
            }

            return (Object.keys(this.linkedDataErrors).length == 0);
        },
        validateStep: function (e) {
            if(e === false){
                return false
            }

            return true;
        },
        goToStep(stepNum, confirm) {
            this.populateElements();
            let hasInvalidLinkedData = !this.validateLinkedData();

            if (typeof(confirm) !== 'undefined') {
                if (!window.confirm(confirm)) {
                    return;
                }
            }

            if(typeof(stepNum) == 'undefined' || stepNum == null) {
                stepNum = (this.activeReportStep + 1);
            }

            if(stepNum < this.activeReportStep) {
                stepNum = this.activeReportStep;
            }

            let scrollToStep = (stepNum > this.activeReportStep);

            if(!hasInvalidLinkedData) {
                this.$validator.validateAll(this.elements[this.activeReportStep]).then(result => {
                    if (result) {

                        this.activeReportStep = stepNum;

                        if(scrollToStep) {
                            setTimeout(() => {
                                let step = this.$el.querySelector(".report-step--" + this.activeReportStep);
                                this.$root.MoveToInstance.move(step, this.focusElm(step));

                            }, 250);
                        }

                    } else {
                        let firstError = this.$el.querySelector('.form__invalid-message[show]');
                        if (firstError != null) {
                            this.$root.MoveToInstance.move(firstError);
                        }
                    }
                });
            }
        },
        nextStep: function () {
            this.goToStep((this.activeReportStep+1));
        },
        focusElm: function (e){
            let element = e.querySelector(".first-form-field");
            if (element) {
                element.focus();
            }
        },
        scrollToStep: function () {

        },
        submitWithStatus: function(status) {
            this.$refs.status.value = status;
            this.submit();
        },
        submit: function () {
            const that = this;

            //console.log(Object.values(this.$el).reduce((obj,field) => { obj[field.name] = field.value; return obj }, {}));
            //return false;

            if(this.status == 'done') {
                return false;
            }

            this.populateElements();
            let hasInvalidLinkedData = !this.validateLinkedData();

            if(!hasInvalidLinkedData) {
                this.$validator.validateAll().then(result => {
                    this.formIsValid = result;

                    if (result) {
                        this.status = 'pending';

                        let formData = new FormData(that.$el);

                        let url = this.updateQueryStringParameter(that.$el.action, 'retry', 1);
                        this.requestPost(url, formData);
                    }
                });
            }
        },
    }
}
