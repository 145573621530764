Vue.component("footer-component", {
    data: function () {
        return {

        }
    },
    computed: {
        menuExpanded: function (){
            return this.$root.menuExpanded;
        }
    },
});