Vue.component('declarations-component', {
    mixins: [Mixins.dataset],
    data: function () {
        return {
            downloadYear: '',
            downloadInProgress: false,
            downloadTimer: false

        }
    },
    computed: {
        downloadDisabled: function() {
            return !(!this.downloadInProgress && this.downloadYear != '');
        }
    },
    created: function () {
        this.firstGet();
    },
    mounted: function() {
    },
    methods: {
        initializeDownload: function(event)
        {
            if(!this.downloadDisabled) {
                let button = event.target;
                let url = this.updateQueryStringParameter(button.getAttribute('href'), 'year', this.downloadYear);
                window.location = url;

                this.downloadInProgress = true;
                this.$cookies.set('fileDownloaded', 0, 30, '/');

                this.downloadTimer = setInterval(function () {
                    var cookieValue = this.$cookies.get('fileDownloaded');
                    if(cookieValue !== null && cookieValue == 1) {
                        clearInterval(this.downloadTimer);
                        this.downloadInProgress = false;
                        this.$cookies.remove('fileDownloaded', '/')
                    }
                }.bind(this), 500);
            }
        }
    }
});
