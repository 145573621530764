Vue.component('two-factor-form-component', {
    data: function () {
        return {
            hasCaptcha: false,
            captchaExecuted: false,
            keyCodeDigits: [8, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105],
            inputElements: null,
            submitButton: null
        }
    },
    mounted: function () {
        this.inputElements = this.$el.querySelectorAll('input[data-index]');
        this.submitButton = this.$el.querySelector('button[type=submit]');
    },
    methods: {
        submit: function ()
        {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.$el.submit();
                }
            });
        },

        keyDown: function(event)
        {
            let inputElement = event.target;
            let keyCode = event.keyCode;
            let inputValue = inputElement.value;
            let inputValueLength = inputValue.length;

            if(this.isShiftKey(event)){
                event.preventDefault();
            } else if(!this.isBackspaceKey(event) && !this.isPasteEvent(event)) {

                if (inputValueLength = 0 || this.keyCodeDigits.indexOf(keyCode) >= 0) {
                    inputElement.value = event.key;
                }

                event.preventDefault();
            }
        },

        keyUp: function(event)
        {
            let inputElement = event.target;
            let inputIterator = parseInt(inputElement.dataset.index);

            let keyCode = event.keyCode;
            let inputValue = inputElement.value;
            let inputValueLength = inputValue.length;

            if(!this.isPasteEvent(event)) {
                if (!this.isBackspaceKey(event) && inputValueLength > 0) {
                    // Door naar volgende input
                    if (this.inputElements.item((inputIterator + 1)) !== null) {
                        this.inputElements.item((inputIterator + 1)).focus();
                    } else {
                        this.submitButton.focus();
                    }
                } else if (inputValueLength == 0) {
                    // Terug naar vorige input
                    if (this.inputElements.item((inputIterator - 1)) !== null) {
                        this.inputElements.item((inputIterator - 1)).focus();
                    }
                }
            }
        },

        change: function(event)
        {
            let inputElement = event.target;

            let inputValue = inputElement.value;
            let inputValueLength = inputValue.length;

            if(inputValueLength > 1) {
                let tokenValue = inputValue.replace(/[^0-9]/ig, '');
                let tokenValueLength = tokenValue.length;

                if(tokenValueLength == 0) {
                    inputElement.value = '';
                } else if(tokenValueLength == 1) {
                    inputElement.value = tokenValue;
                } else {
                    for(let x = 0 ; x < tokenValueLength ; x++) {
                        let tokenChar = tokenValue[x];
                        let tokenInput = this.inputElements.item(x);
                        if(tokenInput !== null) {
                            tokenInput.value = tokenChar;
                        }
                        this.submitButton.focus();
                    }
                }
            }
        },
        isPasteEvent: function(event) {
            return ((event.ctrlKey || event.metaKey) && event.keyCode == 86);
        },
        isShiftKey: function(event){
          return (event.shiftKey)
        },
        isBackspaceKey: function(event) {
            return (event.keyCode == 8);
        },
        isArrowKey: function(event) {
            return (event.keyCode == 37 || event.keyCode == 39);
        },
        isTabKey: function (event) {
            return (event.keyCode == 9 || event.keyCode == 9 && event.shiftKey)
        }
    },
});
