Vue.component('laravel-vue-pagination', {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        limit: {
            type: Number,
            default: 0
        },
        showDisabled: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'default',
            validator: value => {
                return ['small', 'default', 'large'].indexOf(value) !== -1;
            }
        },
        align: {
            type: String,
            default: 'left',
            validator: value => {
                return ['left', 'center', 'right'].indexOf(value) !== -1;
            }
        }
    },

    methods: {
        onPaginationChangePage (page) {
            this.$emit('pagination-change-page', page);
        }
    },

    template: '<renderless-laravel-vue-pagination\n' +
        '        :data="data"\n' +
        '        :limit="limit"\n' +
        '        :show-disabled="showDisabled"\n' +
        '        :size="size"\n' +
        '        :align="align"\n' +
        '        v-on:pagination-change-page="onPaginationChangePage">\n' +
        '\n' +
        '        <ul class="pagination"\n' +
        '            :class="{\n' +
        '                \'pagination-sm\': size == \'small\',\n' +
        '                \'pagination-lg\': size == \'large\',\n' +
        '                \'justify-content-center\': align == \'center\',\n' +
        '                \'justify-content-end\': align == \'right\'\n' +
        '            }"\n' +
        '            v-if="computed.total > computed.perPage"\n' +
        '            slot-scope="{ data, limit, showDisabled, size, align, computed, prevButtonEvents, nextButtonEvents, pageButtonEvents }">\n' +
        '\n' +
        '            <li class="page-item pagination-prev-nav" :class="{\'disabled\': !computed.prevPageUrl}" v-if="computed.prevPageUrl || showDisabled">\n' +
        '                <a class="page-link" href="#" aria-label="Previous" :tabindex="!computed.prevPageUrl && -1" v-on="prevButtonEvents">\n' +
        '                    <slot name="prev-nav">\n' +
        '                        <span aria-hidden="true">&laquo;</span>\n' +
        '                        <span class="sr-only">Previous</span>\n' +
        '                    </slot>\n' +
        '                </a>\n' +
        '            </li>\n' +
        '\n' +
        '            <li class="page-item pagination-page-nav" v-for="(page, key) in computed.pageRange" :key="key" :class="{ \'active\': page == computed.currentPage }">\n' +
        '                <a class="page-link" href="#" v-on="pageButtonEvents(page)">\n' +
        '                    {{ page }}\n' +
        '                    <span class="sr-only" v-if="page == computed.currentPage">(current)</span>\n' +
        '                </a>\n' +
        '            </li>\n' +
        '\n' +
        '            <li class="page-item pagination-next-nav" :class="{\'disabled\': !computed.nextPageUrl}" v-if="computed.nextPageUrl || showDisabled">\n' +
        '                <a class="page-link" href="#" aria-label="Next" :tabindex="!computed.nextPageUrl && -1" v-on="nextButtonEvents">\n' +
        '                    <slot name="next-nav">\n' +
        '                        <span aria-hidden="true">&raquo;</span>\n' +
        '                        <span class="sr-only">Next</span>\n' +
        '                    </slot>\n' +
        '                </a>\n' +
        '            </li>\n' +
        '\n' +
        '        </ul>\n' +
        '\n' +
        '    </renderless-laravel-vue-pagination>'
});