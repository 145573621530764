Vue.component('promotions-report-form-component', {
    mixins: [Mixins.reportForm],
    data: function() {
        return {
        }
    },
    mounted: function () {
        this.readyForInput = true;

        if(!this.$props.editing) {
            let promotionParam = this.getQueryStringParameter(window.location, 'promotion');
            if(promotionParam !== null) {
                this.nextStep();
            }
        }
    },
});
