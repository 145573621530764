Vue.component('collapsible-component', {
    props: ['lessInfo', 'moreInfo'],
    data: function () {
        return {
            open: false
        }
    },
    methods: {
        onToggle() {
            this.open = !this.open;
        }
    },
    template: '<div class="collapsible">' +
                    '<div class="collapsible__buttons">' +
                        '<a href="javascript:void(0);" @click="onToggle" class="collapsible__button" :class="{\'collapsible__button--open\': open}">' +
                            '<span class="collapsible__button-icon" :class="{\'collapsible__button-icon--open\': open}">' +
                                '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 11 6.5" style="enable-background:new 0 0 11 6.5;" xml:space="preserve"><g id="Symbols"><g id="down" transform="translate(-5.000000, -9.000000)"><g transform="translate(11.000000, 11.000000) rotate(90.000000) translate(-11.000000, -11.000000) "><polygon id="Fill-1-Copy" class="st0" points="10,6 9,7 13.5,11.5 9,16 10,17 10,17 14.5,12.5 15.5,11.5 15.5,11.5 14.5,10.5"/></g></g></g></svg>' +
                            '</span>' +
                            '<span class="collapsible__button-text"  :class="{\'collapsible__button-text--open\': open}">{{ open ? lessInfo : moreInfo }}</span>' +
                        '</a>' +
                    '</div>' +
                    '<transition name="slide">' +
                        '<div class="collapsible__content" v-if="open">' +
                            '<slot></slot>' +
                        '</div>' +
                    '</transition>' +
                '</div>'
});
