Vue.component('blocks-slider-component', {
    data: function () {
        return {
            flkty: null
        }
    },
    mounted: function () {
        this.initializeFlickity();
    },
    methods: {
        initializeFlickity() {
            let slides = this.$el.querySelectorAll('.block').length;
            if (!slides || slides < 2) {
                return false
            }

            this.flkty = new Flickity(this.$refs.blocksSlider, {
                watchCSS: true,
                autoPlay: false,
                cellAlign: 'left',
                contain: true,
                pageDots: true,
                prevNextButtons: false,
                draggable: true,
                wrapAround: true,
                imagesLoaded: true
            });
        }
    },
});