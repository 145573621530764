Vue.component('meet-up-shifts-component', {
    mixins: [Mixins.dataset],
    props: ['delayFirstGet'],
    data: function () {
        return {}
    },
    methods: {
        firstGetWithLog() {
            this.firstGet();
        }
    },
    created: function () {
        if (this.$props.delayFirstGet) {
            this.$root.$on('meet-up:first-get', this.firstGetWithLog);
        } else {
            this.firstGet();
        }
    }
});
