Vue.component('meet-ups-component', {
    mixins: [Mixins.dataset],
    data: function () {
        return {
        }
    },
    computed: {
    },
    created: function () {
        this.firstGet();
    },
    mounted: function() {
    },
    methods: {
    }
});
