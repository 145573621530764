Vue.component('chatroom-shifts-component', {
    mixins: [ Mixins.dataset ],
    props: [ 'delayFirstGet' ],
    data: function () {
        return {
        }
    },
    created: function () {
        if(this.$props.delayFirstGet) {
            this.$root.$on('chatroom:first-get', this.firstGet);
        } else {
            this.firstGet();
        }

        this.$on('signout-complete', this.onSignout);
    },
    methods: {
        onSignout(payload) {
            this.data.forEach((item) => {
                if(item.external_id == payload) {
                    item.participating = false;
                }
            })
        }
    }
});
