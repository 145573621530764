Vue.component('avatar-cropper', {
    props: {
        trigger: {
            type: [String, Element],
            required: true
        },
        uploadHandler: {
            type: Function,
        },
        uploadUrl: {
            type: String,
        },
        uploadHeaders: {
            type: Object,
        },
        uploadFormName: {
            type: String,
            default: 'file'
        },
        uploadFormData: {
            type: Object,
            default() {
                return {}
            }
        },
        cropperOptions: {
            type: Object,
            default() {
                return {
                    aspectRatio: 1,
                    autoCropArea: 1,
                    viewMode: 1,
                    movable: false,
                    zoomable: false,
                }
            }
        },
        outputOptions: {
            type: Object,
            default() {
                return {
                    width: 512,
                    height: 512
                }
            }
        },
        outputMime: {
            type: String,
            default: 'image/jpeg'
        },
        outputQuality: {
            type: Number,
            default: 0.9
        },
        mimes: {
            type: String,
            default: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'
        },
        labels: {
            type: Object,
            default() {
                return {
                    submit: "Opslaan",
                    cancel: "Annuleren"
                }
            }
        }
    },
    data() {
        return {
            cropper: undefined,
            dataUrl: undefined,
            filename: undefined
        }
    },
    methods: {
        destroy() {
            this.cropper.destroy()
            this.$refs.input.value = '';
            this.dataUrl = undefined;

            this.$emit('destroyed');
        },
        submit() {
            this.$emit('submit')
            if (this.uploadUrl) {
                this.uploadImage()
            } else if (this.uploadHandler) {
                this.uploadHandler(this.cropper)
            } else {
                this.$emit('error', 'No upload handler found.', 'user')
            }
            this.destroy()
        },
        pickImage() {
            this.$refs.input.click()
        },
        createCropper() {
            this.cropper = new Cropper(this.$refs.img, this.cropperOptions)
        },
        uploadImage() {
            this.cropper.getCroppedCanvas(this.outputOptions).toBlob((blob) => {
                let form = new FormData()
                let xhr = new XMLHttpRequest()
                let data = Object.assign({}, this.uploadFormData)

                for (let key in data) {
                    form.append(key, data[key])
                }

                form.append(this.uploadFormName, blob, this.filename)

                this.$emit('uploading', form, xhr)

                xhr.open('POST', this.uploadUrl, true)

                for (let header in this.uploadHeaders) {
                    xhr.setRequestHeader(header, this.uploadHeaders[header])
                }

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        let response = ''
                        try {
                            response = JSON.parse(xhr.responseText)
                        } catch (err) {
                            response = xhr.responseText
                        }
                        this.$emit('completed', response, form, xhr)

                        if ([200, 201, 204].indexOf(xhr.status) > -1) {
                            this.$emit('uploaded', response, form, xhr)
                        } else {
                            this.$emit('error', 'Image upload fail.', 'upload', xhr)
                        }
                    }
                }
                xhr.send(form);
            }, this.outputMime, this.outputQuality)
        }
    },
    mounted() {
        // listen for click event on trigger
        let trigger = typeof this.trigger == 'object' ? this.trigger : document.querySelector(this.trigger)
        if (!trigger) {
            this.$emit('error', 'No avatar make trigger found.', 'user')
        } else {
            trigger.addEventListener('click', this.pickImage)
        }

        // listen for input file changes
        let fileInput = this.$refs.input
        fileInput.addEventListener('change', () => {
            if (fileInput.files != null && fileInput.files[0] != null) {
                let reader = new FileReader()
                reader.onload = (e) => {
                    this.dataUrl = e.target.result
                }

                reader.readAsDataURL(fileInput.files[0])

                this.filename = fileInput.files[0].name || 'unknown'
                this.$emit('changed', fileInput.files[0], reader)
            }
        })
    }
});