Vue.component('sos-courses-component', {
    mixins: [Mixins.dataset],
    data: function () {
        return {
        }
    },
    created: function () {
        this.firstGet();
    },
    computed: {
        upcomingSessions() {
            let upcomingSessions = [];
            if(this.hasData) {
                this.data.forEach((item) => {
                    if(item.sessions && item.sessions.length > 0) {
                        let itemUpcomingSessions = item.sessions.filter((session) => {
                            return session.is_upcoming;
                        });
                        if(itemUpcomingSessions.length > 0) {
                            let firstSession = Object.assign({}, itemUpcomingSessions[0]);
                            firstSession.sos_course = Object.assign({}, item);
                            delete firstSession.sos_course.sessions;
                            upcomingSessions.push(firstSession);
                        }
                    }
                });
            }
            return upcomingSessions;
        },
        hasUpcomingSessions() {
            return this.upcomingSessions.length > 0;
        }
    }
});
