Vue.component('declarations-form-component', {
    mixins: [Mixins.reportForm],
    props: ['routeAmountPerKm', 'routeUrl', 'linesUrl', 'destroyUrl'],
    data: function () {
        return {
            declaration: {
                status: (document.getElementById('declaration-hidden-status').value || 'concept'),
                declared_at: (document.getElementById('declaration-hidden-declared_at').value || null),
                amount_lines: parseFloat((document.getElementById('declaration-hidden-amount_lines').value || 0)),
                amount_gift: parseFloat((document.getElementById('declaration-hidden-amount_gift').value || 0)),
                amount_gift_percentage: parseFloat((document.getElementById('declaration-hidden-amount_gift_percentage').value || 0)),
                amount_payout: parseFloat((document.getElementById('declaration-hidden-amount_payout').value || 0)),
            },

            current_line: null,
            current_line_index: null,

            lines: [],

            lastRoute: null,
            routeRequestWait: 100,
            routeRequestTimer: null,
            routeRequestBusy: false,
            routeRequestError: false,

            limitAmountTotal: 10000,

            deleteTimer: null,
            isDeleting: false,

            show: 'form', // form | gift | thanks
        }
    },

    watch: {
        status: function(newValue, oldValue) {
            if(newValue == 'done' && oldValue != 'done' && !this.isDeleting) {
                this.show = 'thanks';
            }
        }
    },

    computed: {
        isDeletable() {
            return (this.editing && this.declaration !== null && (this.declaration.status == 'concept' || this.declaration.status == 'denied'));
        },
        isClearable()
        {
            return !this.editing;
        },
        hasManualAttachments: function()
        {
            if(this.lines.length > 0) {
                for(let x = 0 ; x < this.lines.length ; x++) {
                    if(this.lines[x].attachments_manual) {
                        return true;
                    }
                }
            }

            return false;
        },
        getDistanceCompensation: function ()
        {
            if(this.current_line.route) {
                // Calculate distance
                let distance = this.current_line.quantity;
                if (distance && typeof (distance) == 'string') {
                    distance = distance.replace(',', '.');
                }

                //distance = parseFloat(distance);

                let compensationTotal = 0;
                if (distance) {
                    compensationTotal = distance * this.current_line.route_amount_per_km;
                    compensationTotal = compensationTotal.toFixed(2);
                }

                this.current_line.amount_payout = compensationTotal;
                return compensationTotal;
            }

            return 0;
        },

        getAmountLines: function () {
            let total = 0;
            if(this.lines !== null){
                this.lines.forEach( line => {
                    total += parseFloat(line.amount_payout);
                });
                //total = parseFloat(total);
                total = total.toFixed(2)
            }

            this.declaration.amount_lines = parseFloat(total);

            this.declaration.amount_payout = this.declaration.amount_lines;
            if(this.declaration.amount_gift > 0) {
                this.declaration.amount_payout -= this.declaration.amount_gift;
            }

            return total;
        },

        getDisplayAmountLines: function () {
            let total = this.getAmountLines;
            return total;
        },

        getAmountGift: function () {
            return this.declaration.amount_gift;
        },

        // doneren op basis van bedrag
        getAmountGiftPercentage: function () {
            let total = this.getAmountLines;
            let value = this.declaration.amount_gift;

            if(total > 0 && value > 0) {

                let donationPercentage = (100 / total) * parseFloat(value);
                return donationPercentage;
            }

            return 0;
        },

        getDisplayAmountGiftPercentage: function () {
            return this.declaration.amount_gift_percentage;
        }
    },
    mounted: function () {
        this.linkedData = [
            {
                url: this.$props.linesUrl,
                data: 'lines',
                fallback: null
            },
        ];

        if(this.$props.editing) {
            this.getLinkedData();
        } else {
            this.readyForInput = true;
        }

    },
    methods: {
        newLine: function()
        {
            return {
                "id": null,
                "type": null,
                "type_text": '',
                "activity_id": null,
                "activity_text": null,
                "date": null,
                "description": null,
                "attachments_manual": 0,
                "attachments": [],
                "route_from": null,
                "route_to": null,
                "route_retour": 0,
                "route_kilometers": 0,
                "route_amount_per_km": 0.00,
                "route_manual": false,
                "quantity": 0,
                "amount_item": 0,
                "amount_payout": 0,
            }
        },

        setLineType: function(key, label, useRoute)
        {
            this.current_line.type = key;
            this.current_line.type_text = label;
            this.current_line.route = !!useRoute;

            this.setLinePrice();
        },

        setLineActivity: function(event)
        {
            let activity_text = null;
            let select = event.target;
            if(select.selectedIndex >= 0) {
                let option = select.options[select.selectedIndex];
                if(typeof(option) != 'undefined') {
                    activity_text = option.innerText;
                }
            }
            this.current_line.activity_text = activity_text;
        },

        priceFormat: function (value)
        {
            let val = new Intl.NumberFormat('nl-NL', {minimumFractionDigits: 2}).format(value);
            return val;
        },

        setLinePrice: function()
        {
            if(this.current_line.route) {

                if(this.current_line.route_amount_per_km == 0) {
                    this.current_line.route_amount_per_km = this.$props.routeAmountPerKm;
                }

                if(!this.current_line.route_manual) {
                    if (this.current_line.route_retour) {
                        this.current_line.quantity = this.current_line.route_kilometers * 2
                    } else {
                        this.current_line.quantity = this.current_line.route_kilometers;
                    }
                }

                this.current_line.amount_item = this.current_line.route_amount_per_km;
            } else {
                this.current_line.quantity = 1;
                this.current_line.amount_item = this.current_line.amount_payout;
            }
        },

        setRouteManual: function()
        {
            this.current_line.route_manual = !this.current_line.route_manual;
            document.querySelector('.declaration-distance__input').focus();

        },

        calculateRoute: function(startRequest)
        {
            if(this.routeRequestBusy || this.current_line.route_manual) {
                return false;
            }

            let routeHasChanged = this.lastRoute == null || (this.lastRoute.from != this.current_line.route_from || this.lastRoute.to != this.current_line.route_to);
            let isValidInput = ((this.current_line.route_from != null && this.current_line.route_from != '') &&
                                    (this.current_line.route_to != null && this.current_line.route_to != ''));
            if(startRequest) {
                if(routeHasChanged && isValidInput) {
                    this.routeRequestBusy = true;
                    this.routeRequestError = false;
                    this.lastRoute = {
                        from: this.current_line.route_from,
                        to: this.current_line.route_to
                    };

                    axios({
                        method: 'post',
                        url: this.$props.routeUrl,
                        data: this.lastRoute
                    }).then((response) => {

                        if(response.data.distance !== null) {
                            this.current_line.route_kilometers = response.data.distance;
                        }

                        this.setLinePrice();

                        this.routeRequestBusy = false;
                    }).catch((error) => {
                        //this.current_line.route_manual = true;

                        this.routeRequestBusy = false;
                        this.routeRequestError = true;
                    });

                }
            } else if(routeHasChanged && isValidInput) {
                clearTimeout(this.routeRequestTimer);
                this.routeRequestTimer = setTimeout(this.calculateRoute.bind(this, true), this.routeRequestWait);
            }
        },

        // openstaande declaratie toevoegen of updaten indien deze al bestaat
        addToLines: function ()
        {
            this.$validator.validateAll().then(result => {
                if(result) {

                    let newObject = JSON.parse(JSON.stringify(this.current_line));

                    if (this.current_line_index !== null && this.lines.length > 0){
                        this.lines[this.current_line_index] = newObject;
                    } else {
                        this.lines.push(newObject);
                    }

                    // Dirty hack to prevent VeeValidate from losing track of the fields
                    this.current_line.type = null;

                    setTimeout(function() {
                        this.current_line = this.newLine();
                        this.current_line_index = null;
                        this.lastRoute = null;
                    }.bind(this));

                }
            });
        },

        // bestaande declaratie bewerken
        changeLine: function (i)
        {
            this.show = 'form';
            this.current_line_index = i;
            this.current_line = this.lines[i];
        },

        // bestaande declaratie verwijderen
        deleteLine: function (i)
        {
            this.$el.querySelector('.declaration-receipt-item--' + i).classList.add('declaration-receipt-item--removed');
            let lines = this.lines;
            let that = this;

            setTimeout(x => {
                lines.splice(i, 1);
                this.$el.querySelector('.declaration-receipt-item--removed').classList.remove('declaration-receipt-item--removed');

                this.current_line = that.newLine();
                this.current_line_index = null;
            }, 350);
        },

        // alle declaraties verwijderen
        clearLines: function ()
        {

            this.$el.querySelectorAll('.declaration-receipt-item').forEach(item => {
                item.classList.add('declaration-receipt-item--removed');
            });

            setTimeout(x => {
                this.lines = [];
                this.current_line = this.newLine();
            }, 500)
        },

        setAmountGiftPercentage: function ()
        {
            let percentage = this.getAmountGiftPercentage;
            this.declaration.amount_gift_percentage = Math.ceil(parseFloat(percentage));
        },

        // doneren op basis van percentage
        changeAmountGiftPercentage: function ()
        {
            let total = this.getAmountLines;
            let donationValue = (total / 100) * this.declaration.amount_gift_percentage;
            donationValue = Math.round(donationValue * 100) / 100;

            this.declaration.amount_gift = donationValue;
        },

        deactivateDeclaration: function(event)
        {
            const that = this;

            this.$dialog
                .confirm({
                    title: event.target.dataset.confirmTitle,
                    body: event.target.dataset.confirmBody
                }, {
                    loader: true,
                    okText: event.target.dataset.confirmOk,
                    cancelText: event.target.dataset.confirmCancel
                })
                .then(function(dialog) {
                    that.isDeleting = true;
                    that.requestDelete(that.destroyUrl, dialog);
                })
                .catch(function() {
                });
        },

        submitWithStatus: function(status) {
            this.$refs.status.value = status;
            this.declaration.status = status;
            this.submit();
        },
    },
    created: function () {
        this.current_line = this.newLine();
    },
    destroyed: function() {
        clearInterval(this.deleteTimer);
    }
});
