Vue.component('splashscreen-component', {
    props: ['cookieName'],
    data: function () {
        return {
            slider: null,
            show: true
        }
    },
    computed: {},
    mounted: function () {
        this.initSlider();
        this.closeSplash();
    },
    methods: {
        closeSplash(){
            let finish = document.querySelectorAll('.closeIntro');
            let splashscreen = document.querySelector('.splashscreen');
            let component = this;

            finish.forEach( btn => {
                btn.addEventListener("click", function() {

                    //if(component.$cookies.get('cookieconsent_status') != 'deny') {
                        component.$cookies.set(component.cookieName, true, '6m'); // TTL = 6 months
                    //}
                    splashscreen.classList.add('splashscreen--hide');

                    setTimeout(function(){
                        component.show = false;
                    }, 300)
                });
            })
        },

        initSlider() {
            let slider = this.$el.querySelector('.splashscreen__slider');

            this.slider = new Flickity(slider, {
                autoPlay: false,
                contain: false,
                pageDots: true,
                draggable: true,
                wrapAround: false,
                arrowShape: {
                    x0: 20,
                    x1: 65, y1: 50,
                    x2: 70, y2: 45,
                    x3: 30
                },

                on: {
                    change: function (index) {
                        let total = slider.querySelectorAll('.splashscreen__slide').length - 1;
                        if(index == total){
                            document.querySelector('.flickity-button.finish').classList.remove('d-none');
                        } else {
                            document.querySelector('.flickity-button.finish').classList.add('d-none');
                        }
                    },
                }
            });
        },
        nextSlide: function() {
            this.slider.next(true, false);
        },
    },
});
