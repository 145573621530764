Vue.component('dossiers-component', {
    mixins: [Mixins.dataset],
    data: function () {
        return {
            flkty: null
        }
    },
    created: function () {
        this.firstGet();
    },
    watch: {
        status: function(newValue, oldValue) {
            if(oldValue != 'done' && newValue == 'done') {
                setTimeout( x => {
                    this.initializeFlickity();
                }, 0)
            }
        },
    },
    methods: {
        initializeFlickity() {
            let slider = this.$el.querySelector('.blocks--slider');
            let slides = this.$el.querySelectorAll('.block').length;

            if (!slides || slides < 2) {
                return false
            }

            this.flkty = new Flickity(slider, {
                watchCSS: true,
                autoPlay: false,
                cellAlign: 'left',
                contain: true,
                pageDots: true,
                prevNextButtons: false,
                draggable: true,
                wrapAround: true,
                imagesLoaded: true
            });
        }
    }
});
