Vue.component('meet-up-init-component', {
    mixins: [Mixins.dataset],
    data: function () {
        return {
        }
    },
    created: function () {
        this.firstGet();
    },
});
