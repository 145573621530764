Vue.component('expandable-session-component', {
    mixins: [ Mixins.expandableBlock ],
    computed: {
        showVolunteers() {
            return true;
        },
        isExpandable() {
            return (this.data && (this.data.remarks || (this.showVolunteers && this.data.volunteers)) || (this.data.report && this.data.report.mode != 'create'));
        },
        showReportCreate() {
            return this.data.report && this.data.report.mode == 'create';
        },
        showReportEditOrShow() {
            return this.data.report && this.data.report.mode != null && this.data.report.mode != 'create';
        }
    },
    methods: {
        toggleBlock: function () {
            if(!this.isExpandable) {
                return false;
            }

            this.blockExpanded = this.blockExpanded!== true;

            this.$emit('expandable-block-stateChange', this.blockExpanded);
        }
    },
});
