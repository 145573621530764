Vue.component('attachment-component', {
    mixins: [Mixins.queryString],
    props: ['url', 'source_type' , 'source_id', 'data'],
    data: function () {
        return {
            loading: true,
            error: false,
            done: false,
            uploading: false
        }
    },
    computed: {
    },
    watch: {
    },
    mounted: function () {
        if(this.data === null || this.data.length == 0) {
            this.loadData();
        } else {
            this.loading = false;
            this.done = true;
        }
    },
    methods: {
        loadData: function() {
            if(this.$props.source_type && this.$props.source_id) {

                const that = this;

                let url = that.$props.url;
                url = that.updateQueryStringParameter(url,'source_type', this.$props.source_type);
                url = that.updateQueryStringParameter(url, 'source_id', this.$props.source_id);

                axios({
                    method: 'get',
                    url: url,
                }).then(function (response) {
                    if(response.data.data) {
                        for(let x = 0 ; x < response.data.data.length ; x++) {
                            that.data.push(response.data.data[x]);
                        }
                    }

                    that.loading = false;
                    that.done = true;
                }).catch(function (error) {
                    that.loading = false;
                    that.error = true;
                });
            } else {
                this.loading = false;
                this.done = true;
            }
        },
        store: function(event) {
            let files = event.target.files;

            if(!files.length) {
                return false;
            }

            let formData = new FormData();

            for(let x = 0 ; x < files.length ; x++) {
                formData.append('file[' + x + ']', files.item(x));

                this.data.push({
                    id: null,
                    name: files.item(x).name,
                    size: files.item(x).size,
                    type: files.item(x).type,
                    uploading: x,
                    state: 'upload'
                });
            }

            this.uploading = true;

            let url = this.$props.url;
            url = this.updateQueryStringParameter(url,'source_type', this.$props.source_type);
            url = this.updateQueryStringParameter(url, 'source_id', this.$props.source_id);

            axios({
                method: 'post',
                url: url,
                data: formData
            }).then(response => {

                let data = (response.data.data || null);

                this.data.forEach(item => {
                    if(item.hasOwnProperty('uploading')) {
                        if(data !== null && data.hasOwnProperty(item.uploading)) {
                            item.id = data[item.uploading].id;
                            item.state = data[item.uploading].state;
                        }
                        delete(item.uploading);
                    }

                });

                this.uploading = false;

            }).catch(error => {

                let errors = (error.response.data.errors || null);

                this.data.forEach(item => {
                    if(item.hasOwnProperty('uploading')) {
                        let name = 'file.' + item.uploading;
                        if(errors !== null && errors.hasOwnProperty(name)) {
                            item.error = errors[name][0].replace(name, item.name);
                            item.state = 'error';
                        }
                        delete(item.uploading);
                    }
                });

                this.uploading = false;
            });

        },

        check: function(event) {
            if(this.uploading) {
                event.preventDefault();
            }
        },

        destroy: function(x, item) {
            // Item splicen
            this.data.splice(x, 1);
        }

    },
});