Vue.component('chat-component', {
    mixins: [ Mixins.dataset ],
    props: ['url', 'person', 'open'],
    data: function () {
        return {
            blockExpanded: false,
            continuesGet: true,
            atBottom: true,
            autoScroll: true,
            badgeCount: 0,
            inputText: '',
            sendingText: false

        }
    },
    computed: {
        showLoading: function() {
            return (this.data == null || this.data.length == 0) && this.loading;
        },
        showError: function() {
            return this.error;
        },
        showChats: function() {
            return (this.data !== null && this.data.length > 0);
        },
        disableTextInput: function() {
            return this.sendingText;
        },
        disableTextButton: function() {
            return (this.disableTextInput || this.inputText == '');
        }
    },
    watch: {
        status: function(newValue, oldValue) {
            //console.log('status: ' + newValue + ' <> ' + oldValue);
            if(oldValue != 'done' && newValue == 'done') {
                this.interval = 5000;
            }

            if(newValue == 'pending' && this.sendingText) {

                if(this.blockExpanded) {
                    this.scrollToBottom(true, false);
                    this.stopGet();
                    this.firstGet();
                }

                this.sendingText = false;
            }
        },
        data: function(newValue, oldValue) {

            let newCount = (newValue !== null ? newValue.length : 0);
            let oldCount = (oldValue !== null ? oldValue.length : 0);

            if(newCount > oldCount) {
                this.badgeCount += (newCount - oldCount);
            }

            this.scrollToBottom();
        }
    },
    mounted: function () {
        this.scrollToBottom(true);

        this.$parent.$on('expandable-block-stateChange', this.stateChange);

        if(this.$props.open === true) {
            this.stateChange(true);
        }
    },
    methods: {

        submit: function () {
            this.$validator.validateAll().then(result => {
                let form = this.$el;
                let analytics = null;

                if(form.dataset.analytics){
                    analytics = form.dataset.analytics;
                }

                if (result && analytics) {
                    if (!this.hasCaptcha || this.captchaExecuted) {
                        this.submitForm(form, analytics);
                    } else {
                        this.$refs.recaptcha.execute();
                    }
                } else if (result) {
                    this.$root.submitForm(form);
                }
            });
        },

        stateChange: function(blockExpanded) {
            this.blockExpanded = blockExpanded;
            if(this.blockExpanded) {
                this.scrollToBottom(true, false);
                this.firstGet();
            } else {
                this.stopGet();
            }
        },

        scrollChange: function(event) {
            let element = this.$el.querySelector(".chat-window");

            /*console.log('element.scrollTop', element.scrollTop);
            console.log('element.scrollHeight', element.scrollHeight);
            console.log('element.offsetHeight', element.offsetHeight);
            console.log('(element.scrollHeight - element.offsetHeight)', (element.scrollHeight - element.offsetHeight));
            console.log('-------');*/

            this.autoScroll = ((element.offsetHeight + element.scrollTop) >= (element.scrollHeight - 30));
        },

        scrollToBottom: function (force) {

            force = (typeof(force) != 'undefined' ? force : false);

            /*console.log('scrollToBottom');
            console.log('force', force);
            console.log('this.autoScroll', this.autoScroll);*/

            if(this.autoScroll || force === true) {
                let element = this.$el.querySelector(".chat-window");
                let currentScrollTop = element.scrollTop;
                let requiredScrollTop = (element.scrollTop = element.scrollHeight);
                element.scrollTop = requiredScrollTop;

                //console.log('requiredScrollTop', requiredScrollTop);

                this.badgeCount = 0;
            }
        },

        sendText: function() {
            // Stop if already sending text
            if(this.sendingText) {
                return false;
            }

            // Stop if message is empty
            if(this.inputText == '') {
                return false;
            }

            // Stop the retrieval of new chats
            this.stopGet();

            // Lower the interval for quicker results
            this.interval = 500;

            // Reset the status
            this.status = 'new';

            // Register us as sending new text
            this.sendingText = true;

            // Send the request
            this.requestPost(this.$props.url, {
                'text': this.inputText
            });

            // Clear the input
            this.inputText = '';
        },

        visibilityChanged: function (isVisible, entry, itemID, external_id) {

            this.isVisible = isVisible
            this.count++

            if(Math.round(entry.intersectionRatio * 100) === 100 && typeof(this.$el.dataset.urlMessageRead) != 'undefined') {
                this.requestPost(this.$el.dataset.urlMessageRead, {message: external_id});
            }
        }
    },
});
