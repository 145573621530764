Mixins.expandableBlock = {
    props: ['data', 'openExpanded'],
    data: function () {
        return {
            blockExpanded: (this.$props.openExpanded === true),
        }
    },
    mounted: function()
    {
        if(this.blockExpanded) {
            this.$emit('expandable-block-stateChange', this.blockExpanded);
        }
    },
    methods: {
        toggleBlock: function () {
            this.blockExpanded = this.blockExpanded!== true;

            this.$emit('expandable-block-stateChange', this.blockExpanded);
        }
    },
};
