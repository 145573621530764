Vue.component('calendar-component', {
    mixins: [Mixins.dataset],
    props: {
        url: String
    },
    data() {
        return {
            selectedMonth: moment().format('YYYY-MM'),
            selectedDate: moment().format('YYYY-MM-DD'),
            data: [],
            flatpickr,
        }
    },
    mounted() {
        let self = this;
        self.firstGet(function () {
            self.flatpickr = flatpickr(self.$refs.calendarView, {
                inline: true,
                //dateFormat: "Y-m-d",
                locale: (document.querySelector('html').getAttribute('lang') || 'nl'),
                onChange: function (selectedDates, dateStr, instance) {
                    self.selectedMonth = moment(selectedDates[0]).format('YYYY-MM');
                    self.selectedDate = moment(selectedDates[0]).format('YYYY-MM-DD');
                },
                onMonthChange: function (selectedDates, dateStr, instance) {
                    self.selectedMonth = instance.currentYear + '-' + ("0" + (instance.currentMonth + 1)).slice(-2);
                },
                onYearChange: function (selectedDates, dateStr, instance) {
                    self.selectedMonth = instance.currentYear + '-' + ("0" + (instance.currentMonth + 1)).slice(-2);
                    self.loadYear(instance.currentYear);
                },
                onDayCreate: function (dObj, dStr, fp, dayElem) {
                    // Utilize dayElem.dateObj, which is the corresponding Date

                    let currDate = moment(dayElem.dateObj).format('YYYY-MM-DD');

                    if (self.data.filter(function (e) {
                        return e.date === currDate
                    }).length > 0) {
                        dayElem.innerHTML += "<span class='calendar-filter__event'></span>";
                    }
                }
            })
        });
    },
    computed: {
        filteredActivities() {
            let self = this;
            return self.data.filter(function (activity) {
                return activity.date === self.selectedDate;
            });
        },
        monthlyActivities() {
            let self = this;
            return self.data.filter(function (activity) {
                return activity.month === self.selectedMonth;
            });
        }
    },
    methods: {
        // loadMonth(year, month) {
        //     let self = this;
        //     self.status = 'pending';
        //     self.filter = {'year': {'value': year}, 'month': {'value': month}};
        //     self.filterGet(function () {
        //         if (self.done) {
        //             self.flatpickr.redraw();
        //         }
        //     });
        // },
        loadYear(year) {
            let self = this;
            self.status = 'pending';
            self.filter = {'year': {'value': year}};
            self.filterGet(function () {
                if (self.done) {
                    self.flatpickr.redraw();
                }
            });
        }
    }
});
