Vue.component('sos-courses-sessions-component', {
    props: [ 'mode', 'delayFirstGet' ],
    mixins: [ Mixins.dataset ],
    data: function () {
        return {
        }
    },
    created: function () {
        if(this.$props.delayFirstGet) {
            this.$root.$on('soscourse:first-get', this.firstGet);
        } else {
            this.firstGet();
        }
    },
});
