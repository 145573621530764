Vue.component('chatroom-shifts-signout-button-component', {
    mixins: [Mixins.dataset],
    props: ['item'],
    data: function () {
        return {
            formIsValid: true
        }
    },
    mounted() {
        if(this.$props.item && !this.$props.item.participating) {
            this.status = 'finished';
        }
    },
    computed: {
        idle() {
            return (this.status == 'new');
        },
    },
    watch: {
        status: function(newValue, oldValue) {
            if(oldValue != 'done' && newValue == 'done') {
                this.status = 'finished';
                this.$emit('signout-complete', this.$props.item.external_id);
            }
        }
    },
    methods: {
        onClick() {
            this.status = 'pending';

            let formData = new FormData();
            formData.append('_method', 'PUT');
            formData.append('shift', this.$props.item.external_id);
            formData.append('participating', 0);

            let url = this.updateQueryStringParameter(this.$props.url, 'retry', 1);
            this.requestPost(url, formData);
        }
    }
});

