Vue.component('trainings-component', {
    mixins: [Mixins.dataset],
    data: function () {
        return {
        }
    },
    props: [ 'delayFirstGet' ],
    computed: {
    },
    created: function () {
        if(this.$props.delayFirstGet) {
            this.$root.$on('chatroom:first-get', this.firstGet);
        } else {
            this.firstGet();
        }
    },
    mounted: function() {
    },
    methods: {
        signOut(item) {
            this.status = 'pending';

            let formData = new FormData();
            formData.append('_method', 'PUT');
            formData.append('training', item.external_id);
            formData.append('status', 'signed_out');

            let url = this.updateQueryStringParameter(this.$props.url, 'retry', 1);
            this.requestPost(url, formData);
        }
    }
});
