Vue.component('block-header-options', {
    props: [
        'item'
    ],
    data() {
        return {
            open: false,
        }
    }
});
