Vue.component('replace-variables-component', {
    props: ['text', 'variables'],
    computed: {
        output() {
            let value = this.text;

            let keyArray = Object.keys(this.variables);

            keyArray.sort(function(a, b){
                // ASC  -> a.length - b.length
                // DESC -> b.length - a.length
                return b.length - a.length;
            });

            keyArray.forEach((key) => {
                value = value.replace(':' + key, this.variables[key])
            });

            return value;
        }
    },
    template: '<span>{{ output }}</span>'
});
