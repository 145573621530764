Vue.component('sos-courses-session-form-component', {
    mixins: [Mixins.reportForm],
    data: function () {
        return {
            session_date: null,
        }
    },
    mounted: function () {
        if(!this.$props.editing) {
            this.readyForInput = true;

            let sosCourseParam = this.getQueryStringParameter(window.location, 'sos_course');
            if(sosCourseParam !== null) {
                this.nextStep();
            }
        }
    },
    methods: {
    },
});
