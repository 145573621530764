Vue.component('sos-courses-sessions-participate-component', {
    mixins: [Mixins.dataset],
    data: function () {
        return {
        }
    },
    created: function () {
        this.firstGet();
    },
    methods: {
        isRoleDisabled(x, role_id) {
            if(!this.data[x].hasOwnProperty('role_options')) {
                return true;
            }

            let roleData = this.data[x].role_options.find(function(element) {
                return element.id == role_id
            })

            if(roleData == null) {
                return true;
            }

            return !roleData.available;
        },
        openRoleChoice(x) {
            Vue.set(this.data[x], 'role_choice', true);
        },
        signIn(x) {
            if(!this.data[x].hasOwnProperty('role') || this.data[x].role == null) {
                this.openRoleChoice(x);
            } else {
                Vue.set(this.data[x], 'role_choice', true);
                Vue.set(this.data[x], 'participating', 1);
                this.signInOrOut(x);
            }
        },
        signOut(x) {
            Vue.set(this.data[x], 'role', null);
            Vue.set(this.data[x], 'participating', 0);
            this.signInOrOut(x);
        },
        signInOrOut(x) {
            this.status = 'pending';

            let formData = new FormData();
            formData.append('_method', 'PUT');
            formData.append('session', this.data[x].external_id);
            formData.append('participating', this.data[x].participating);
            formData.append('role', this.data[x].role);

            let url = this.updateQueryStringParameter(this.$props.url, 'retry', 1);
            this.requestPost(url, formData);
        }
    }
});
