Vue.component('sos-courses-sessions-wrapper-component', {
    data: function () {
        return {
            ranFirstGet: false,
            status: 'new',
            progress: null
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        onChange(payload) {
            if(payload.status == 'done' && !this.ranFirstGet) {
                this.$root.$emit('soscourse:first-get');
                this.status = payload.status;
            }
        }
    },
    computed: {
        done: function() {
            return (this.status == 'done');
        },
        error: function() {
            return (this.status == 'failed');
        },
        loading: function() {
            return (this.status == 'new' || this.status == 'pending' || this.status == 'busy');
        },
        submitting: function() {
            return (this.status == 'pending' || this.status == 'busy');
        },
        hasData: function() {
            return (this.status == 'done');
        }
    },
});
