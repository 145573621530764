Vue.component('sos-courses-alert-form-component', {
    mixins: [ Mixins.reportForm ],
    data: function () {
        return {
        }
    },
    watch: {
        status: function(newValue, oldValue) {
            if(oldValue != 'done' && newValue == 'done') {
                this.status = 'finished';
                this.$el.reset();
            }
        }
    },
});
