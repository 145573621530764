Vue.component('meet-up-activities-component', {
    mixins: [Mixins.dataset],
    props: {
        url: String,
        updateUrl: String,
    },
    data() {
        return {
        }
    },
    computed: {
        isManaging() {
            return this.hasData && this.data.some(i => i.is_managing === 1);
        }
    },
    created: function () {
        this.firstGet();
    },
    mounted() {
    },
    methods: {
        signOut(index) {
            const self = this;
            self.status = 'pending';
            let activity = self.data[index];
            activity.is_participating = false;
            self.requestPut(self.updateUrl.replace(':id', activity.external_id), {
                external_id: activity.external_id,
                is_participating: activity.is_participating,
                return: 'availability'
            });
        },
        signUp(index) {
            const self = this;
            self.status = 'pending';
            let activity = self.data[index];
            activity.is_participating = true;
            self.requestPut(self.updateUrl.replace(':id', activity.external_id), {
                external_id: activity.external_id,
                is_participating: activity.is_participating,
                return: 'availability'
            });
        }
    }
});
