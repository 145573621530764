Vue.component('meet-up-alert-form-component', {
    mixins: [Mixins.reportForm],
    data() {
        return {}
    },
    watch: {
        status(newValue, oldValue) {
            if (oldValue != 'done' && newValue == 'done') {
                this.status = 'finished';
                this.$el.reset();
            }
        }
    },
});
