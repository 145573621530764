Vue.component('datepicker-component', {
    mixins: [],
    props: ['value','initial-value', 'name', 'label', 'min', 'max', 'placeholder', 'input-class', 'label-class'],
    data() {
        return {
            hasNativeSupport: false,
            inputDateFormat: 'DD-MM-YYYY',
            outputDateFormat: 'YYYY-MM-DD',
            flatpickrInstance: null,
            flatpickrConfig: {
                altInput: false,
                dateFormat: 'd-m-Y',
                clickOpens: true,
                allowInput: true,
                locale: (document.querySelector('html').getAttribute('lang') || 'nl')
            },
        }
    },
    computed: {
        outputType() {
            return (this.hasNativeSupport ? 'date' : 'text');
        },
    },
    watch: {
    },
    created() {
        // Check for native datepicker support
        let fakeElement = document.createElement('input');
        fakeElement.setAttribute('type', 'date');
        this.hasNativeSupport = (fakeElement.type == 'date');
    },
    mounted() {
        if(!this.hasNativeSupport) {
            if (this.min !== null) {
                this.flatpickrConfig.minDate = moment(this.min, this.outputDateFormat).format(this.inputDateFormat);
            }
            if (this.max !== null) {
                this.flatpickrConfig.maxDate = moment(this.max, this.outputDateFormat).format(this.inputDateFormat);
            }
            this.flatpickrInstance = flatpickr(this.$refs.inputDate, this.flatpickrConfig);
            this.flatpickrInstance.config.onChange.push(function(selectedDates, dateStr, instance) {
                if(!selectedDates.length) {
                    return false;
                }
                this.emit('input', 'input', moment(selectedDates[0], this.inputDateFormat).format(this.outputDateFormat));
            }.bind(this));


            if(this.initialValue && this.initialValue !== null) {
                let date = moment(this.initialValue, this.outputDateFormat);
                if(date.isValid()) {
                    this.flatpickrInstance.setDate(date.format(this.inputDateFormat), false);
                }
            } else if(this.value && this.value !== null) {
                let date = moment(this.value, this.outputDateFormat);
                if(date.isValid()) {
                    this.flatpickrInstance.setDate(date.format(this.inputDateFormat), false);
                }
            }
        } else {
            if(this.initialValue && this.initialValue !== null) {
                this.emit('output', 'input', this.initialValue);
            }
        }
    },
    destroyed() {
    },
    methods: {
        emit(source, type, payload) {
            this.$emit(type, payload);
        },
        onInputInput() {
            let currentInputValue = this.$refs.inputDate.value;
            let currentOutputValue = '';
            let date = moment(currentInputValue, this.inputDateFormat);
            if(date.isValid()) {
                currentOutputValue = date.format(this.outputDateFormat);
                if(currentInputValue == date.format(this.inputDateFormat)) {
                    this.flatpickrInstance.setDate(currentInputValue, false);
                }
            }
            this.emit('output', 'input', currentOutputValue);
        },
        onInputFocus(event) {
            this.emit('input', 'focus', event)
        },
        onInputBlur(event) {
            this.emit('input', 'blur', event)
        },
        onOutputInput(event) {
            if(!this.hasNativeSupport) {
                let currentOutputValue = this.$refs.outputDate.value;
                let currentInputValue = '';

                let date = moment(currentOutputValue, this.outputDateFormat);
                if (date.isValid()) {
                    currentInputValue = date.format(this.inputDateFormat);
                }
                this.flatpickrInstance.setDate(currentInputValue, false);
            }
            this.emit('output', 'input', event.target.value)
        },
        onOutputFocus(event) {
            this.emit('output', 'focus', event)
        },
        onOutputBlur(event) {
            this.emit('output', 'blur', event)
        }
    },

    template: '<div>' +
        '   <label ' +
        '       v-if="label" ' +
        '       :class="labelClass" ' +
        '       v-text="label" ' +
        '       ref="label"></label>' +

        '   <div class="input-wrapper">' +
        '      <input ' +
        '          :type="outputType" ' +
        '          :class="inputClass" ' +
        '          :value="value" ' +
        '          @input="onOutputInput($event)" ' +
        '          @focus="onOutputFocus($event)" ' +
        '          @blur="onOutputBlur($event)"  ' +
        '          :name="name" ' +
        '          :min="min" ' +
        '          :max="max" ' +
        '          :placeholder="placeholder" ' +
        '          ref="outputDate"' +
        '          v-show="hasNativeSupport">' +

        '      <input ' +
        '          v-if="!hasNativeSupport" ' +
        '          type="text" ' +
        '          :class="inputClass" ' +
        '          @input="onInputInput" ' +
        '          @focus="onInputFocus($event)" ' +
        '          @blur="onInputBlur($event)" ' +
        '          :placeholder="placeholder" ' +
        '          ref="inputDate">' +
        '   </div>' +
        '</div>'

});
